<template>
  <div style="height: 100%">
    <van-nav-bar
      title="分类"
      left-arrow
      @click-left="close"
      :fixed="true"
      placeholder
    >
    </van-nav-bar>
    <div v-if="goodsValue.length > 0" style="height: calc(100% - 50px)">
      <van-tree-select
        height="100%"
        :items="goodsValue"
        :main-active-index.sync="mainActiveIndex"
        @click-nav="onNavClick"
      >
        <template slot="content">
          <van-cell-group class="goods-cell-group">
            <van-cell
              ><van-col span="16">{{
                goodsValue[mainActiveIndex].name
              }}</van-col
              ><van-col
                span="8"
                class="fz12 color999"
                style="text-align: right"
                @click="
                  $router.push({
                    name: 'ClassificationDetail',
                    query: {
                      categoryId: goodsValue[mainActiveIndex].id,
                      text: goodsValue[mainActiveIndex].name,
                    },
                  })
                "
                >查看更多</van-col
              ></van-cell
            >
            <van-cell>
              <div class="flex-action">
                <div
                  class="flex-cur"
                  style="flex-direction: column"
                  v-for="(i, key) in items"
                  :key="key"
                >
                  <img
                    :src="i.picUrl"
                    @click="
                      $router.push({
                        name: 'ClassificationDetail',
                        query: {
                          categoryId2: i.id,
                          text: i.name,
                        },
                      })
                    "
                  />
                  <p>{{ i.name }}</p>
                </div>
              </div>
            </van-cell>
          </van-cell-group>
        </template>
      </van-tree-select>
    </div>
  </div>
</template>
<script>
import { goodsCategory } from "@/api/proprietary/proprietary";
export default {
  name: "classification",
  data() {
    return {
      goodsValue: [],
      mainActiveIndex: 0,
      items: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
  },
  methods: {
    getList() {
      goodsCategory().then((res) => {
        console.log(res);
        this.goodsValue = res.data.data;
        this.goodsValue = this.goodsValue.map((v) => ({
          ...v,
          text: v.name,
        }));
        this.items = this.goodsValue[0].children;
      });
    },
    close() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    onNavClick(index) {
      this.mainActiveIndex = index;
      console.log(this.goodsValue[index].children);
      this.items = this.goodsValue[index].children;
    },
  },
};
</script>
<style lang="less" scoped>
.flex-action {
  display: flex;
  flex-wrap: wrap;
}
.flex-cur {
  padding-top: 10px;
  display: flex;
  align-items: center;
  width: 33%;
  flex-direction: column;
}
.flex-cur img {
  width: 50px;
  height: 50px;
}
.flex-cur p {
  width: 100%;
  text-align: center;
}
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  color: black;
}
</style>