var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('van-nav-bar',{attrs:{"title":"分类","left-arrow":"","fixed":true,"placeholder":""},on:{"click-left":_vm.close}}),(_vm.goodsValue.length > 0)?_c('div',{staticStyle:{"height":"calc(100% - 50px)"}},[_c('van-tree-select',{attrs:{"height":"100%","items":_vm.goodsValue,"main-active-index":_vm.mainActiveIndex},on:{"update:mainActiveIndex":function($event){_vm.mainActiveIndex=$event},"update:main-active-index":function($event){_vm.mainActiveIndex=$event},"click-nav":_vm.onNavClick}},[_c('template',{slot:"content"},[_c('van-cell-group',{staticClass:"goods-cell-group"},[_c('van-cell',[_c('van-col',{attrs:{"span":"16"}},[_vm._v(_vm._s(_vm.goodsValue[_vm.mainActiveIndex].name))]),_c('van-col',{staticClass:"fz12 color999",staticStyle:{"text-align":"right"},attrs:{"span":"8"},on:{"click":function($event){return _vm.$router.push({
                  name: 'ClassificationDetail',
                  query: {
                    categoryId: _vm.goodsValue[_vm.mainActiveIndex].id,
                    text: _vm.goodsValue[_vm.mainActiveIndex].name,
                  },
                })}}},[_vm._v("查看更多")])],1),_c('van-cell',[_c('div',{staticClass:"flex-action"},_vm._l((_vm.items),function(i,key){return _c('div',{key:key,staticClass:"flex-cur",staticStyle:{"flex-direction":"column"}},[_c('img',{attrs:{"src":i.picUrl},on:{"click":function($event){return _vm.$router.push({
                      name: 'ClassificationDetail',
                      query: {
                        categoryId2: i.id,
                        text: i.name,
                      },
                    })}}}),_c('p',[_vm._v(_vm._s(i.name))])])}),0)])],1)],1)],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }